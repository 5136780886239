import {NgStyle} from '@angular/common';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {PaginatorModule} from 'primeng/paginator';
import {TableLazyLoadEvent} from 'primeng/table';
import {NotificationComponent} from 'src/app/components/sub-components/notification/notification.component';
import {NotificationContext} from 'src/app/enums/backend/notification-context.enum';
import {Role} from 'src/app/enums/backend/role.enum';
import {ErrorCode} from 'src/app/enums/core/error-code.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {NotificationsFacade} from 'src/app/facades/notifications.facade';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {GAP_BUTTONS, GAP_Y, TABLE_SUMMARY_MESSAGE} from 'src/app/helpers/core/site-config.helper';
import {PRIORITY_SEVERITY_MAP} from 'src/app/helpers/radiology-tasks.helper';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {NotificationDto} from 'src/app/interfaces/backend/dto/notification-dto.interface';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {getTableLoad} from 'src/app/utilities/table.util';
import {displayError} from 'src/app/utilities/toast.util';

const RAD_STANDALONE_COMPONENTS = [
    NotificationComponent
];

const RAD_MODULES = [
    ButtonsModule
];

const PRIME_NG_MODULES = [
    PaginatorModule
];

@Component({
    selector: 'app-notifications',
    standalone: true,
    imports: [
        NgStyle,
        TranslateModule,
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES,
        ...PRIME_NG_MODULES
    ],
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
    @Input() public dialogMode: boolean = false;

    protected perPage: number = 10;
    protected pageable: Pageable = {page: 0, size: this.perPage};

    protected readonly PRIORITY_SEVERITY_MAP = PRIORITY_SEVERITY_MAP;
    protected readonly GAP_Y = GAP_Y;
    protected readonly GAP_BUTTONS = GAP_BUTTONS;
    protected readonly TABLE_SUMMARY_MESSAGE = TABLE_SUMMARY_MESSAGE;
    protected readonly Size = Size;
    protected readonly NotificationContext = NotificationContext;
    protected readonly Role = Role;

    constructor(
        protected notificationsFacade: NotificationsFacade,
        protected profileFacade: ProfileFacade,
        private injector: Injector
    ) {
    }

    public ngOnInit() {
        if (!this.dialogMode) return;

        this.notificationsFacade.loadData(this.pageable);
    }

    protected openNotificationsDialog() {
        this.notificationsFacade.displayNotificationsSidebar = false;
        this.notificationsFacade.displayNotificationsDialog = true;
    }

    protected getNotifications($event: TableLazyLoadEvent) {
        this.pageable = getTableLoad($event, this.perPage).pageable;
        this.notificationsFacade.loadData(this.pageable);
    }

    private refreshData() {
        this.dialogMode ?
            this.notificationsFacade.loadData(this.pageable) :
            this.notificationsFacade.loadDataPeek();
        this.notificationsFacade.loadCountOfUnreadWebNotifications();
    }

    protected onMarkAsRead($event: NotificationDto, fn?: () => void) {
        const id = $event.id;
        if (!id) {
            displayError(this.injector, ErrorCode.MISSING_REQUEST_DATA);
            return;
        }

        const callback = () => {
            this.refreshData();
            fn?.();
        };

        this.notificationsFacade.markNotificationAsRead(id, callback);
    }

    protected markAllNotificationsAsRead() {
        const callback = () => this.refreshData();
        this.notificationsFacade.markAllNotificationsAsRead(callback);
    }

    protected onGoToSubject($event: NotificationDto) {
        const fn = () => this.notificationsFacade.goToSubject($event);
        $event.isRead ? fn() : this.onMarkAsRead($event, fn);
    }
}