import {FormGroup} from '@angular/forms';
import {InputType} from 'src/app/enums/core/input-type.enum';
import {FormModel} from 'src/app/models/forms/base/form.model';
import {LoginFormData} from 'src/app/models/forms/login-form.model';

export enum ProfileManagerFormControl {
    PROFILE = 'profile'
}

export interface ProfileManagerFormData {
    [ProfileManagerFormControl.PROFILE]: LoginFormData | null;
}

export class ProfileManagerFormModel extends FormModel<ProfileManagerFormData> {
    public constructor(data?: LoginFormData) {
        super();
        this.patchValues(data, {forInit: true});
    }

    private initiateFormDefinitions() {
        this.prepareFormDefinitions({
            controlNames: ProfileManagerFormControl,
            inputsTypes: {[ProfileManagerFormControl.PROFILE]: InputType.DROPDOWN}
        });
    }

    protected build(): FormGroup {
        this.initiateFormDefinitions();
        return this.generateFormGroup(ProfileManagerFormControl);
    }

    public toFormData(): ProfileManagerFormData {
        return this.getFormData();
    }

    public toFormControlData(controlName: ProfileManagerFormControl) {
        return this.getFormControlData(controlName);
    }
}