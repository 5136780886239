<div class="d-flex align-items-center position-relative">
    @if (finalIcon) {
        <p-button styleClass="p-button-icon-only"
                  [size]="Size.SMALL"
                  [outlined]="finalOutlined"
                  [rounded]="true"
                  [severity]="severity"
                  [pTooltip]="finalLabel | translate"
                  (onClick)="onClick()">
            <app-google-icon [icon]="finalIcon"/>
        </p-button>
    }

    @if (item?.items) {
        <app-google-icon class="p-button-icon-right"
                         [icon]="item?.expanded ? GoogleIcon.ARROW_DROP_UP : GoogleIcon.ARROW_DROP_DOWN"/>
    }

    @if (notificationsCount) {
        <p-badge styleClass="p-badge-dot"
                 [severity]="Severity.DANGER"/>
    }
</div>