import {FormGroup} from '@angular/forms';
import {InputType} from 'src/app/enums/core/input-type.enum';
import {Validator} from 'src/app/enums/core/validator.enum';
import {FormModel} from 'src/app/models/forms/base/form.model';

export enum HelpFormControl {
    SUBJECT = 'subject',
    REASON = 'reason',
    MESSAGE_CONTENT = 'messageContent'
}

export interface HelpFormData {
    [HelpFormControl.SUBJECT]: string;
    [HelpFormControl.REASON]: string;
    [HelpFormControl.MESSAGE_CONTENT]: string;
}

export class HelpFormModel extends FormModel<HelpFormData> {
    public constructor() {
        super();
    }

    private initiateFormDefinitions() {
        this.prepareFormDefinitions({
            controlNames: HelpFormControl,
            inputsTypes: {
                [HelpFormControl.SUBJECT]: InputType.DROPDOWN,
                [HelpFormControl.REASON]: InputType.TEXT,
                [HelpFormControl.MESSAGE_CONTENT]: InputType.EDITOR
            },
            validators: {
                [HelpFormControl.SUBJECT]: [{name: Validator.REQUIRED}],
                [HelpFormControl.REASON]: [{name: Validator.REQUIRED}],
                [HelpFormControl.MESSAGE_CONTENT]: [{name: Validator.REQUIRED}]
            },
            labels: {
                [HelpFormControl.SUBJECT]: 'ticketCategory',
                [HelpFormControl.REASON]: 'subject',
                [HelpFormControl.MESSAGE_CONTENT]: 'content'
            }
        });
    }

    protected build(): FormGroup {
        this.initiateFormDefinitions();
        return this.generateFormGroup(HelpFormControl);
    }

    public toFormData(): HelpFormData {
        return this.getFormData();
    }

    public toFormControlData(controlName: HelpFormControl) {
        return this.getFormControlData(controlName);
    }
}