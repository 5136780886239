import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Controller} from 'src/app/enums/backend/core/controller.enum';
import {RadiologyStatisticControllerEndpoint} from 'src/app/enums/backend/core/endpoints/radiology-statistic-controller-endpoint.enum';
import {RadStatisticDto} from 'src/app/interfaces/backend/dto/rad-statistic-dto.interface';
import {EndpointsService} from 'src/app/services/core/endpoints.service';

@Injectable({providedIn: 'root'})
export class RadiologyStatisticControllerService {
    private controller: Controller = Controller.RADIOLOGY_STATISTIC;

    public constructor(
        private endpointsService: EndpointsService
    ) {
    }

    public getStatisticAssignedDescriptionOrdersForEachDoctor(): Observable<RadStatisticDto[]> {
        return this.endpointsService.send(this.controller, RadiologyStatisticControllerEndpoint.GET_STATISTIC_ASSIGNED_DESCRIPTION_ORDERS_FOR_EACH_DOCTOR);
    }

    public getStatisticCountOrdersCompletedForEachDoctor(): Observable<RadStatisticDto[]> {
        return this.endpointsService.send(this.controller, RadiologyStatisticControllerEndpoint.GET_STATISTIC_COUNT_ORDERS_COMPLETED_FOR_EACH_DOCTOR);
    }

    public getStatisticCountOrdersForEachUnits(): Observable<RadStatisticDto[]> {
        return this.endpointsService.send(this.controller, RadiologyStatisticControllerEndpoint.GET_STATISTIC_COUNT_ORDERS_FOR_EACH_UNITS);
    }
}