<div class="d-flex flex-column gap-1">
    @for (item of items; track item.key) {
        <app-sidebar-item [item]="item"
                          (itemClick)="handleItemClick(item)"/>
        @if (item.items; as innerItems) {
            @if (item.expanded) {
                <div [ngClass]="{
                'mb-2': item.expanded && ($index + 1) < (items ?? []).length,
                'ps-6': indent
                }">
                    <app-sidebar [routeData]="routeData"
                                 [items]="innerItems"
                                 [pathIndex]="pathIndex + 1"
                                 [indent]="indent"
                                 (itemClick)="handleItemClick($event)"/>
                </div>
            }
        }
    }
</div>