import {FormGroup} from '@angular/forms';
import {InputType} from 'src/app/enums/core/input-type.enum';
import {FormModel} from 'src/app/models/forms/base/form.model';

export enum SearchFormControl {
    SEARCH = 'search'
}

export interface SearchFormData {
    [SearchFormControl.SEARCH]: string;
}

export class SearchFormModel extends FormModel<SearchFormData> {
    public constructor() {
        super();
    }

    private initiateFormDefinitions() {
        this.prepareFormDefinitions({
            controlNames: SearchFormControl,
            inputsTypes: {[SearchFormControl.SEARCH]: InputType.SEARCH}
        });
    }

    protected build(): FormGroup {
        this.initiateFormDefinitions();
        return this.generateFormGroup(SearchFormControl);
    }

    public toFormData(): SearchFormData {
        return this.getFormData();
    }

    public toFormControlData(controlName: SearchFormControl) {
        return this.getFormControlData(controlName);
    }
}