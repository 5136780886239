<app-confirm-dialog [(visible)]="visible"
                    [header]="('deletionConfirmation.header' | translate) + ' ' + ('deletionConfirmation.subjectsContexts.header.' + subjectContext() | translate)"
                    [bodyContent]="('deletionConfirmation.body' | translate) + ' ' +
                      ('deletionConfirmation.subjectsContexts.body.' + subjectContext() | translate) + (subject() ? ': ' + subject() : '') + '?'"
                    cancelButtonLabel="no"
                    confirmButtonLabel="delete"
                    [confirmButtonSeverity]="Severity.DANGER"
                    [width]="width"
                    [maxWidth]="maxWidth"
                    [fullWidth]="fullWidth"
                    [fullHeight]="fullHeight"
                    [centerHeader]="centerHeader"
                    [headerIcon]="headerIcon"
                    [headerIconColor]="headerIconColor"
                    [headerIconNewRow]="headerIconNewRow"
                    [blur]="blur"
                    [closable]="closable"
                    [noCloseOnConfirm]="noCloseOnConfirm"
                    (xClose)="onXClose()"
                    (radCancel)="onRadCancel()"
                    (confirm)="onConfirm()"/>